.MuiOutlinedInput-root, .MuiTextField-root{
    margin: 0 !important;
    border-radius: 8px !important;
}

.MuiOutlinedInput-input {
    padding: 11.5px 14px !important;
    &:focus{
        outline: none !important;
        border: none !important;
    }
}