@import "src/assets/styles/abstracts/variables";

.button-primary {
    
    
    border-radius: 4px;
    border: 1px solid #ED1C24;
display: inline-flex;
padding: 10px 25px;

justify-content: center;
align-items: center;
box-sizing: border-box;
font-weight: 700;
font-size: 14px;
line-height: 22px;
/* identical to box height */




color: $color-primary;
text-align: center;
svg {
    path {
        fill: $color-primary;
    }
}
&.active {
    box-shadow: 0px 12px 20px rgba(204, 0, 8, 0.14);
color: #fff;
background: #ED1C24;
}
&.btn-light-red {
    background: rgba(237, 28, 36, 0.05);
    color: #ED1C24;
    border-color: rgba(237, 28, 36, 0.05);
}
&.button-secondary {
    background: rgba(17, 13, 63, 0.04);
    color: rgba(17, 13, 63, 0.65);
    border-color: rgba(237, 28, 36, 0.05);
}
&.button-sm {
    padding: 5px 25px;
    font-weight: 700;
font-size: 13px;
line-height: 17px;
}

&.size-md {
    min-width: 150px;
}


& + .button-primary {
    margin-left: 15px;
}
}

.button-links {
    font-weight: 500;
font-size: 16px;
color: $color-primary;   
line-height: 140%;
display: inline-flex;
    align-items: center;
    &.red-links  {
        color: #FF0F0F;
    }
    &.blue-links  {
        color: #2D6AD8;
    }
    &.link-grey  {
        color: rgba(17, 28, 66, 0.45);
    }
}

button:disabled {
    cursor: not-allowed;
    background-color: rgba($color: grey, $alpha: 0.1);
}

/* alert */
.alert-button {
   
border-radius: 38px;
display: inline-flex;
padding: 5px 15px;
    &.alert-grey-1 {
        background: #F1F2F4;
    }
    &.alert-green-1 {
        background: rgba(0, 174, 59, 0.08);
        color: #00AE3B;
    }
    &.alert-red-1 {
        background: rgba(237, 28, 36, 0.08);
        color: #ED1C24;
    }
}


/* button text */
.button-primary-title-1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
color: $color-primary;
display: block;
margin-bottom: 4px;
}
.button-primary-title-2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
color: $color-primary;
display: block;

}

.button-primary-icons {
    flex: 0 0 36px;
    display: flex;
    .pi {
        font-size: 22px;
    }
}