@import "/src/assets/styles/abstracts/variables";

.layout-container {
     
      @include respond(phone) {
            padding-left: 0px;
            padding-right: 0px;
        }
       .layout-contents-right {
          //    flex: 1;
          margin-left: 255px;
              padding: 25px;
              @include respond(phone) {
                margin-left: 0;
                padding: 0;
            }
            &.collapse-closed {
                margin-left: 30px;
            }
       }
       .layout-contents {
        //    flex: 1;
       
            padding: 25px;
            @include respond(phone) {
              
              padding: 0;
          }
     }
}

/* profile */
/* profile pages */
.format-user-ui-1 {
       padding:25px 70px;
       
   }

   .user-ui-page-1 {
       display: flex;
   
       .user-ui-contents-1 {
           flex: 1;
       }
        
   }
