@import "./abstracts/variables";
@import "./typography/_fonts";
@import "./resets/resets";
@import "./components/buttons";
@import "./abstracts/utilities";
@import 'primeflex/primeflex.scss';

/* header row */
.ui-header + .layout-container {
    margin-top: 76px;
    @include respond(phone) {
        margin-top: 119px;    
    }
}

/* */
.p-component {
    font-family: 'roboto-ft';
    font-weight: initial;
    font-size: inherit;
}


/* list */
.ul-type-none {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* */
.max-container {  
   padding-left: 50px;
   padding-right: 50px;
   @include respond(phone) {
    padding-left: 15px;
   padding-right: 15px;
   }
}

/* breadcrum */
.breadcrumb-1 {
    padding-bottom: 15px;
    @include respond(phone) {
        padding-top: 15px;
    }
    .p-breadcrumb {
        padding: 0;
        border: none;
        border-radius: 0;
        ul {
            li {
                &.p-breadcrumb-chevron:first-child {
                    display: none;
                }

                &:last-child {
                    font-weight: bold;
                }

            }

        }
        .p-breadcrumb-chevron {
            &.pi-chevron-right {
&::before {
    content: "/";
    font-size: 19px;
    font-weight: bold;
}
            }
        } 
    }
}

/* headings */
.page-heading-1 {
    font-weight: 600;
font-size: 24px;
line-height: 29px;
color: #111C42;
}



.page-subheading-1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;  
    color: #111C42;
}
.total-records-1 {
    font-weight: 400;
    font-size: 16px;
color: #111C42;
}

/* all book lists */
.all-books-1 {
    display: flex;
    flex-wrap: wrap;
    .book-items {
        width: 50%;
        margin: 15px;
        max-width: calc(25% - 30px);
        @include respond(phone) {
            max-width: calc(50% - 10px);
margin: 5px;
        }
    }
    &.lg-card-3 {
        .book-items {
            max-width: calc(33.33% - 50px);
            @include respond(phone) {
                max-width: calc(50% - 10px);
    margin: 5px;
            }
        }
    }
    &.lg-card-4 {
        .book-items {
            max-width: calc(25% - 30px);
            @include respond(phone) {
                max-width: calc(50% - 10px);
    margin: 5px;
            }
        }
    }
}

/* */
.divider-heading-1 {
    display: flex;
    align-items: center;
    font-weight: 400;
font-size: 13px;
line-height: 16px;
color: rgba(17, 28, 66, 0.45);
    &::after {
        content: "";
        background: rgba(17, 28, 66, 0.12);
        display: block;
        height: 1px;
        flex: 1;
margin-left: 10px;
        
    }
}

/* spaces */
.space-divider-b2 {
    padding-bottom: 15px;
}

/* tags ui */
.product-wise-tag {
    background: #E6ECF6;
border-radius: 4px;
padding: 5px 8px;
display: inline-flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-size: 13px;
line-height: 16px;
color: #000;
&.printbook {
color: #2D6AD8;
background: #E6ECF6;
}
&.virtualbook {
color: #CE4ABF;
background: #FAF3F9;
}
&.pocketbook {
color: #25C16F;
background: #EBF5F0;
}
&.book {
    color: #F88822;
    background: #FAF6F3;
    }

}


/* genric css */
.row-space-divider-1 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.divider-line-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    
}

.page-card-1 {
    display: flex;
    @include respond(phone) {
        flex-wrap: wrap;
    }
    .page-card-left {
        flex: 1;
        @include respond(phone) {
            flex: inherit;
            width: 100%;
        }
    }
    .page-card-right {
        flex: 0 0 35%;
    margin-left: 25px;
    @include respond(phone) {
        flex: inherit;
        width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }
    }
}

.ui-page-bg-1 {
    //background: #F7F8FA;
}

.flex-lists {
    display: flex;    
    li {
       
        & + li {
            margin-left: 15px;
        }
    }
}

.ul-info-lists {
    li {
        font-weight: 400;
font-size: 16px;
line-height: 19px;
color: rgba(17, 28, 66, 0.65);
@include respond(phone) {
    margin-top: 15px;
}

& + li {
    margin-top: 15px;
}
    }

    /* lavel 2 */
    &.flex-list {
li {
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
        &.active {
            path {
                fill: $color-primary;
                stroke:$color-primary;
            }
        }
    }
}
    }
}

.empty-data {
    text-align: center;
    padding: 100px 50px;
    .empty-data-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
    .empty-data-details {
        .empty-data-h1 {
            font-weight: 700;
font-size: 27px;
line-height: 33px;
color: #111C42;
display: block;
margin-bottom: 10px;
        }
        .empty-data-text-1 {
            font-weight: 400;
font-size: 14px;
line-height: 22px;
color: #111C42;
display: block;
        }
        .button-primary {
            margin-top: 25px;
        }
    }
}


.input-switch-1 {
    font-weight: 500;
font-size: 16px;
line-height: 19px;
color: rgba(17, 13, 63, 0.45);
display: flex;
align-items: center;
.input-switch-text {
    margin-right: 5px;
}
}


/* Items In Cart */
.quantity-items {
    &.mob-center {
    @include respond(phone) {
        display: flex;
    justify-content: center;
    }
}
   .p-inputnumber {
    background: #FFFFFF;
border: 1px solid rgba(17, 28, 66, 0.08);
border-radius: 10px;
.p-inputnumber-input {
width: 40px;
text-align: center;
border: none;
}
.p-button {
    &.p-button-icon-only {
color: $color-primary;
border: none;
    }
    &:hover {
        background: $color-primary;
        color: #fff;
    }
}

   }
}



.media-card-1 {
    &.cart-colls-1 {
        .ul-info-lists {
            li {
                justify-content: flex-end;
                @include respond(phone) {
                    justify-content: center;
                }
            }
        }
    }
}

/* */
.user-info-card-1 {
    background: #FFFFFF;
box-shadow: 0px 3px 4px rgba(17, 13, 63, 0.08);
border-radius: 10px;
background: #fff;
.user-info-card-head {
    border-bottom: 1px solid rgba(17, 13, 63, 0.07);
    padding:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info-card-counts {
        width: 34px;
        height: 34px;
        background: #FDF1E6;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
font-size: 16px;
line-height: 19px;

color: $color-primary;
margin-right: 15px;
border-radius: 50px;
    }
    .user-info-card-h1 {
        font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #110D3F;
    }
    .button-links {
        svg {
            margin-right: 10px;
        }
    }

    .flex-coll-row {
        display: flex;
        align-items: center;
        .flex-coll-1 {
            & + .flex-coll-1 {
                margin-left: 15px;
            }
        }
    }
}
.user-info-card-contents {    
    padding:20px;
    .button-primary {
        &.dashed-outer-1 {
            width: 100%;
            border: 2px dashed rgba(248, 136, 34, 0.45);
border-radius: 10px;
font-weight: 400;
svg {
    margin-right: 7px;
}
        }
    }
}
}

.card-ui-items-1 {
    background: #FFFFFF;
border: 1px solid rgba(17, 28, 66, 0.15);
border-radius: 10px;
.card-ui-items-head {
    display: flex;
    padding: 15px 20px;
    font-weight: 400;
font-size: 16px;
line-height: 21px;
color: rgba(17, 28, 66, 0.75);
background: #E6FAF7;
border-radius: 10px 10px 0px 0px;
svg {
    margin-right: 7px;
}
}
.card-ui-items-content {
    padding:20px;
    font-weight: 400;
font-size: 16px;
line-height: 26px;
color: rgba(17, 13, 63, 0.75);
min-height: 150px;

   .card-ui-items-h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;    
    color: rgba(17, 13, 63, 0.75);
    display: block;
   }
   .card-ui-items-text-1 {
margin-bottom: 5px;
   }
 
}

.card-ui-items-footer {
    display: flex;
    padding: 15px 20px;
    
    .button-links {
margin-right: 10px;
    }
   }

   & + .card-ui-items-1 {
    margin-left: 15px;
   }
}


/* */
.white-space-nowrap {
    white-space: nowrap;
}


/* ///////////////////////////////// scrollbar ///////////////////////////////////////////*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius:16px;
    border: 1px solid rgba(0, 0, 0, .30);
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#C1C1C1;
    border-radius:50px;
    &:hover { background-color:$color-primary; }
  }
  ::-webkit-scrollbar-button {display:none}
  *{
    scrollbar-face-color:#C1C1C1;
    scrollbar-shadow-color:#e2e2e2;
    scrollbar-track-color:#e2e2e2;
    scrollbar-arrow-color:#e2e2e2;
  }
  
  :focus {  
    outline-color:$color-primary;    
  }

.text-elipsis{
    display: -webkit-box!important;
    -webkit-box-orient: vertical!important;
    overflow: hidden!important;
    &.l1 {
        -webkit-line-clamp: 1;
    }
    &.l2 {
        -webkit-line-clamp: 2;
    }
    &.l3 {
        -webkit-line-clamp: 3;
    }
    &.l10 {
        -webkit-line-clamp: 10;
    }
}



/* modal p-dialog modify */
.p-dialog {
    border-radius: 20px;
    padding: 20px;
    background: #fff;
    .p-dialog-header {
        padding: 0;
        .p-dialog-header-icons {
            right: 10px;
    top: 8px;
    position: absolute;
    z-index: 5;
        }
    }
    .p-dialog-content {
        padding: 0;
    }

    &.sm-dialog {
        width: 425px;
    }

    &.md-dialog {
        width: 700px;
    }

    &.lg-dialog {
        width: 850px;
    }
    &.full-dialog {
        width: 95%;
    }
}


/* scroll bar */
.scroll-on { overflow: auto !important;}
.scroll-off { overflow: hidden !important;}
