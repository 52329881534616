

$color-primary:#ED1C24;
//$color-secondary: #f88922;
$color-background: #fff6ee;

// mixins
@mixin respond($breakpoint) {
    @if $breakpoint==phone-sm {
        @media only screen and (max-width: 36em) {
            @content;
        }
    }
    @if $breakpoint==phone {
        @media only screen and (max-width: 48.125em) {
            @content;
        }
    }
    @if $breakpoint==tab-port {
        @media only screen and (max-width: 62em) {
            @content;
        }
    }
    @if $breakpoint==tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        }
    }
    @if $breakpoint==big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content;
        }
    }
}

@mixin circle($width, $height) {
    border-radius: 50%;
    height: $height;
    width: $width;
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
